var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('div',{staticClass:"d-flex flex-column"},[_c('v-app-bar',{attrs:{"app":"","color":"primary","dark":"","clipped-left":""}},[_c('v-container',{staticClass:"fill-height py-0",attrs:{"fluid":""}},[_c('v-btn',{attrs:{"type":"submit","text":""},on:{"click":_vm.goToHome}},[_c('strong',[_vm._v("Conciliar-Me")])]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"text":""}},'v-btn',attrs,false),on),[_c('span',[_vm._v(" Conciliação ")])])]}}])},[_c('v-list',_vm._l((_vm.rotinaConciliacao),function(item,index){return _c('v-list-item',{key:index,attrs:{"to":item.route}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"text":""}},'v-btn',attrs,false),on),[_c('span',[_vm._v(" Organizações ")])])]}}])},[_c('v-list',_vm._l((_vm.rotinasOrganizacoes),function(item,index){return _c('v-list-item',{key:index,attrs:{"to":item.route}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"text":""}},'v-btn',attrs,false),on),[_c('span',[_vm._v(" Integrações ")])])]}}])},[_c('v-list',_vm._l((_vm.rotinasIntegracoes),function(item,index){return _c('v-list-item',{key:index,attrs:{"to":item.route}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":""}},'v-btn',attrs,false),on),[_c('span',[_vm._v(" Usuários ")])])]}}])},[_c('v-list',_vm._l((_vm.rotinasUsuarios),function(item,index){return _c('v-list-item',{key:index,attrs:{"to":item.route}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1),_c('v-spacer'),(_vm.isStaging())?_c('v-btn',{attrs:{"color":"red"}},[_vm._v(" staging ")]):_vm._e()],1)],1),_c('v-navigation-drawer',{attrs:{"mini-variant":"","app":"","clipped":"","permanent":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-list-item',{on:{"click":_vm.logOut}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-logout ")])],1),_c('v-list-item-title',[_vm._v(" Sair ")])],1)]},proxy:true}])},[_c('v-list',{attrs:{"nav":"","dense":""}},_vm._l((_vm.rotinaConciliacao),function(item){return _c('v-list-item',{key:item.title,attrs:{"to":item.route,"link":""}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-icon',[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(item.icon)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.title)+" ")])]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1)}),1)],1)],1),_c('v-main',[_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }