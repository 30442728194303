<template>
  <v-app>
    <div class="d-flex flex-column">
      <v-app-bar app color="primary" dark clipped-left>
        <v-container fluid class="fill-height py-0">
          <v-btn type="submit" text @click="goToHome">
            <strong>Conciliar-Me</strong>
          </v-btn>

          <v-menu offset-y>
            <template #activator="{ on, attrs }">
              <v-btn text class="ml-4" v-bind="attrs" v-on="on">
                <span> Conciliação </span>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in rotinaConciliacao" :key="index" :to="item.route">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-menu offset-y>
            <template #activator="{ on, attrs }">
              <v-btn text class="mx-2" v-bind="attrs" v-on="on">
                <span> Organizações </span>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in rotinasOrganizacoes"
                :key="index"
                :to="item.route"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-menu offset-y>
            <template #activator="{ on, attrs }">
              <v-btn text class="mx-2" v-bind="attrs" v-on="on">
                <span> Integrações </span>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in rotinasIntegracoes"
                :key="index"
                :to="item.route"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-menu offset-y>
            <template #activator="{ on, attrs }">
              <v-btn text v-bind="attrs" v-on="on">
                <span> Usuários </span>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in rotinasUsuarios" :key="index" :to="item.route">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-spacer />
          <v-btn v-if="isStaging()" color="red"> staging </v-btn>
        </v-container>
      </v-app-bar>

      <v-navigation-drawer mini-variant app clipped permanent>
        <v-list nav dense>
          <v-list-item v-for="item in rotinaConciliacao" :key="item.title" :to="item.route" link>
            <v-tooltip right>
              <template #activator="{ on, attrs }">
                <v-list-item-icon>
                  <v-icon v-bind="attrs" v-on="on">
                    {{ item.icon }}
                  </v-icon>
                </v-list-item-icon>
              </template>

              <span> {{ item.title }} </span>
            </v-tooltip>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <template #append>
          <v-list-item @click="logOut">
            <v-list-item-icon>
              <v-icon> mdi-logout </v-icon>
            </v-list-item-icon>
            <v-list-item-title> Sair </v-list-item-title>
          </v-list-item>
        </template>
      </v-navigation-drawer>
    </div>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { TOKEN, USUARIO } from '@/common/constants'
import store from '@/store'

export default {
  name: 'Aplicacao',

  data: () => ({
    rotinasUsuarios: [],
    rotinasOrganizacoes: [],
    rotinasIntegracoes: [],
    rotinaTitulos: [],
    rotinaPagamentos: [],
    rotinaConciliacao: [],
    rotinaPagamentosConciliados: []
  }),

  created() {
    const menuUsuarios = [
      {
        title: 'Usuários',
        icon: 'mdi-account',
        route: { name: 'Usuario' }
      },
      {
        title: 'Perfis',
        icon: 'mdi-account-multiple',
        route: { name: 'Perfil' }
      },
      {
        title: 'Permissões',
        icon: 'mdi-lock-open',
        route: { name: 'Permissao' }
      }
    ]

    const menuOrganizacoes = [
      {
        title: 'Empresas',
        icon: 'mdi-domain',
        route: { name: 'Empresa' }
      },
      {
        title: 'Organizações',
        icon: 'mdi-format-list-bulleted',
        route: { name: 'Organizacao' }
      }
    ]

    const menuIntegracoes = [
      {
        title: 'Integração PayPal',
        icon: 'mdi-domain',
        route: { name: 'IntegracaoPayPal' }
      },
      {
        title: 'Integração PagSeguro',
        icon: 'mdi-domain',
        route: { name: 'IntegracaoPagSeguro' }
      },
      {
        title: 'Integração GetNet',
        icon: 'mdi-domain',
        route: { name: 'IntegracaoGetNet' }
      },
      {
        title: 'Integração Rede',
        icon: 'mdi-domain',
        route: { name: 'IntegracaoRede' }
      },
      {
        title: 'Integração Systextil',
        icon: 'mdi-domain',
        route: { name: 'IntegracaoSystextil' }
      }
    ]

    const menuConciliacao = [
      {
        title: 'Pagamentos',
        icon: 'mdi-credit-card-outline',
        route: { name: 'Pagamento' }
      },
      {
        title: 'Títulos',
        icon: 'mdi-credit-card-multiple-outline',
        route: { name: 'Titulo' }
      },
      {
        title: 'Conciliação',
        icon: 'mdi-credit-card-search-outline',
        route: { name: 'Conciliacao' }
      },
      {
        title: 'Pagamentos Conciliados',
        icon: 'mdi-credit-card-check-outline',
        route: { name: 'PagamentosConciliados' }
      }
    ]

    const rotinasPermitidas = store.getters.buscarRotinasPermitidasUsuario

    this.rotinasUsuarios = menuUsuarios.filter((item) => {
      return rotinasPermitidas.includes(item.route.name)
    })

    this.rotinasOrganizacoes = menuOrganizacoes.filter((item) => {
      return rotinasPermitidas.includes(item.route.name)
    })

    this.rotinasIntegracoes = menuIntegracoes.filter((item) => {
      return rotinasPermitidas.includes(item.route.name)
    })

    this.rotinaConciliacao = menuConciliacao.filter((item) => {
      return rotinasPermitidas.includes(item.route.name)
    })
  },

  methods: {
    logOut() {
      localStorage.removeItem(TOKEN)
      localStorage.removeItem(USUARIO)

      this.$router.push('/login')
    },

    goToHome() {
      this.$router.push({ path: 'home' })
    },

    isStaging() {
      return process.env.NODE_ENV === 'staging'
    }
  }
}
</script>
